import { createContext, useContext, useEffect, useState } from 'react'
import { ROLE } from '~/constants/constant'
import useAuth from '~/hooks/useAuth'
import { UserContext } from './UserContext'
import axiosConfig from '~/api/axios-config'

export const AppContext = createContext({})

export const AppProvider = ({ children }) => {
	const [isOpenCarts, setIsOpenCarts] = useState(false)
	const { user } = useContext(UserContext)
	const [cartList, setCartList] = useState([])
	const auth = useAuth()

	useEffect(() => {
		if (!user._id) return
		if (auth === ROLE.User) {
			axiosConfig
				.get(`/cart`)
				.then((res) => setCartList(res.data.carts))
				.catch((err) => console.log(err))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user._id])

	return (
		<AppContext.Provider
			value={{ isOpenCarts, setIsOpenCarts, cartList, setCartList }}
		>
			{children}
		</AppContext.Provider>
	)
}
