import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import axiosConfig from '~/api/axios-config'
import { UserContext } from '~/context/UserContext'

const Orders = () => {
	const { user } = useContext(UserContext)
	const [orders, setOrders] = useState([])
	useEffect(() => {
		axiosConfig
			.get(`/order`, { params: { id: user._id } })
			.then((res) => {
				setOrders(res.data.orders)
			})
			.catch((err) => console.log(err))
	})

	return (
		<div>
			<h1 className='ss-title py-2 text-5xl mt-[5rem]'>
				Danh sách tất cả đơn hàng:
			</h1>
			<div className='mt-[5rem]'>{}</div>
		</div>
	)
}

export default Orders
