import React, { useEffect, useState } from 'react'
import Promotions from '../../components/Promotion/Promotions'
import Banner from './Banner'
import BrandList from './BrandList'
import ProductList from './ProductList'
import axiosConfig from '~/api/axios-config'
import LayerLoading from '../Admin/Components/LayerLoading'

const Home = () => {
	const [products, setProducts] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		axiosConfig
			.get('/products/getall')
			.then((res) => {
				setProducts(res.data.products)
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [])

	return (
		<>
			<Banner />
			<Promotions />
			<BrandList />
			{loading ? (
				<div className='w-full aspect-square'>
					<LayerLoading scale={'1.4'} />
				</div>
			) : (
				<ProductList productList={products} />
			)}
		</>
	)
}

export default Home
