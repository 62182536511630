import React from 'react'

import s from '../NotFound.module.scss'

const YellowBackFig = () => {
	return (
		<g
			id={`${s.Layer_12} ${s.yellowbackfig}`}
			data-name='Layer 12'
		>
			<path
				className={s.cls1}
				d='M600.87,872H156a4,4,0,0,0-3.78,4.19h0a4,4,0,0,0,3.78,4.19H600.87a4,4,0,0,0,3.78-4.19h0A4,4,0,0,0,600.87,872Z'
			/>
			<rect
				className={s.cls1}
				x='680.91'
				y='871.98'
				width='513.38'
				height='8.39'
				rx='4.19'
				ry='4.19'
			/>
			<path
				className={s.cls1}
				d='M1480,876.17h0c0,2.32,2.37,4.19,5.3,4.19h350.61c2.93,0,5.3-1.88,5.3-4.19h0c0-2.32-2.37-4.19-5.3-4.19H1485.26C1482.33,872,1480,873.86,1480,876.17Z'
			/>
			<rect
				className={s.cls1}
				x='492.21'
				y='920.64'
				width='249.8'
				height='8.39'
				rx='4.19'
				ry='4.19'
			/>
			<path
				className={s.cls1}
				d='M1549.14,924.84h0a4.19,4.19,0,0,0-4.19-4.19H1067.46a14.66,14.66,0,0,1,.35,3.21v1A4.19,4.19,0,0,0,1072,929h472.94A4.19,4.19,0,0,0,1549.14,924.84Z'
			/>
			<path
				className={s.cls1}
				d='M865.5,924.84h0a4.19,4.19,0,0,0,4.19,4.19h82.37a12.28,12.28,0,0,1-.19-2v-2.17a4.19,4.19,0,0,0-4.19-4.19h-78A4.19,4.19,0,0,0,865.5,924.84Z'
			/>
			<rect
				className={s.cls1}
				x='915.6'
				y='981.47'
				width='54.72'
				height='8.39'
				rx='4.19'
				ry='4.19'
			/>
			<path
				className={s.cls1}
				d='M730.33,985.67h0c0,2.32,4.23,4.19,9.44,4.19h104.3c5.22,0,9.44-1.88,9.44-4.19h0c0-2.32-4.23-4.19-9.44-4.19H739.78C734.56,981.47,730.33,983.35,730.33,985.67Z'
			/>
			<rect
				className={s.cls1}
				x='997.06'
				y='981.47'
				width='78.11'
				height='8.39'
				rx='4.19'
				ry='4.19'
			/>

			<g id={`${s.roundconf}`}>
				<path
					className={`${s.cls1} ${s.circle} ${s.c1}`}
					d='M536.41,155.14a17.77,17.77,0,1,0,17.77,17.77A17.77,17.77,0,0,0,536.41,155.14Zm0,28.68a10.9,10.9,0,1,1,10.9-10.9A10.9,10.9,0,0,1,536.41,183.81Z'
				/>
				<path
					className={`${s.cls1} ${s.circle} ${s.c2}`}
					d='M1345.09,82.44a17.77,17.77,0,1,0,17.77,17.77A17.77,17.77,0,0,0,1345.09,82.44Zm0,28.68a10.9,10.9,0,1,1,10.9-10.9A10.9,10.9,0,0,1,1345.09,111.12Z'
				/>
				<path
					className={`${s.cls1} ${s.circle} ${s.c3}`}
					d='M70.12,363A17.77,17.77,0,1,0,87.89,380.8,17.77,17.77,0,0,0,70.12,363Zm0,28.68A10.9,10.9,0,1,1,81,380.8,10.9,10.9,0,0,1,70.12,391.7Z'
				/>
				<path
					className={`${s.cls1} ${s.circle} ${s.c4}`}
					d='M170.47,751.82a17.77,17.77,0,1,0,17.77,17.77A17.77,17.77,0,0,0,170.47,751.82Zm0,28.68a10.9,10.9,0,1,1,10.9-10.9A10.9,10.9,0,0,1,170.47,780.5Z'
				/>
				<path
					className={`${s.cls1} ${s.circle} ${s.c5}`}
					d='M1457.34,762.73a17.77,17.77,0,1,0,17.77,17.77A17.77,17.77,0,0,0,1457.34,762.73Zm0,28.68a10.9,10.9,0,1,1,10.9-10.9A10.9,10.9,0,0,1,1457.34,791.4Z'
				/>
				<path
					className={`${s.cls1} ${s.circle} ${s.c6}`}
					d='M1829.15,407.49a17.77,17.77,0,1,0,17.77,17.77A17.77,17.77,0,0,0,1829.15,407.49Zm0,28.68a10.9,10.9,0,1,1,10.9-10.9A10.9,10.9,0,0,1,1829.15,436.17Z'
				/>
			</g>
		</g>
	)
}

export default YellowBackFig
