import React, { useEffect, useState } from 'react'

import style from './Products.module.scss'
import Carts from '../../components/Cart/Carts'
import axiosConfig from '~/api/axios-config'
import LayerLoading from '../Admin/Components/LayerLoading'

const Products = () => {
	const [phoneList, setPhoneList] = useState([])
	const [laptopList, setLaptopList] = useState([])
	const [tvList, setTvList] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		Promise.allSettled([
			axiosConfig.get(`/products/getall`, {
				params: { category_name: 'điện thoại' },
			}),
			axiosConfig.get(`/products/getall`, {
				params: { category_name: 'laptop' },
			}),
			axiosConfig.get(`/products/getall`, {
				params: { category_name: 'Tivi' },
			}),
		])
			.then(([phone, laptops, tv]) => {
				const phoneList = phone.value.data.products
				const laptopList = laptops.value.data.products
				const tvList = tv.value.data.products
				console.log(tvList)
				setPhoneList(phoneList)
				setLaptopList(laptopList)
				setTvList(tvList)
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setLoading(false)
			})
	}, [])

	return loading ? (
		<div className='w-full aspect-square'>
			<LayerLoading scale={'1.4'} />
		</div>
	) : (
		<section className={style.products}>
			<div className='mt-[5rem]'>
				<h2 className='font-bold text-5xl py-2 ss-title mt-[5rem]'>
					Điện thoại:
				</h2>
				{phoneList?.length > 0 && <Carts cartList={phoneList} />}
				{laptopList?.length > 0 && (
					<>
						<h2 className='font-bold text-5xl py-2 ss-title mt-[5rem]'>
							Laptop:
						</h2>
						<Carts cartList={laptopList} />
					</>
				)}
				{tvList?.length > 0 && (
					<>
						<h2 className='font-bold text-5xl py-2 ss-title mt-[5rem]'>
							Tivi:
						</h2>
						<Carts cartList={tvList} />
					</>
				)}
			</div>
		</section>
	)
}

export default Products
